import React from 'react'
import get from 'lodash/get'

import Page from 'templates/Page'
import Meta from 'components/Meta'
import Layout from 'components/Layout'

const SiteIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <form class="text-center form-signin">
        <img class="mb-4" src="/img/brightfame.svg" alt="" width="183" height="43" />
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        <label for="inputEmail" class="sr-only">Email address</label>
        <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus />
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="Password" required />
        <div class="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        <p class="mt-5 mb-3 text-muted">&copy; 2018 Brightfame</p>
      </form>
    </Layout>
  )
}

export default SiteIndex
